import React from 'react'
import { useTranslation } from 'react-i18next'
import Container from '~/components/Container'
import ArrowRight from '~/components/icons/ArrowRight'
import Link from '~/components/Link'
import SlotPicker from '~/components/SlotPicker'
import Typography from '~/components/Typography'
import { ROUTES } from '~/constants/routes'
import { useTrackElementReached } from '~/hooks/useTrackElementReached'

export type BookASlotProps = {
  isLogged: boolean
}

const BookASlot = ({ isLogged }: BookASlotProps) => {
  const { t } = useTranslation()
  const ref = useTrackElementReached<HTMLDivElement>('BOOK_A_SLOT_REACHED', {
    page: 'Home'
  })

  return (
    <Container
      id="book-a-slot"
      component="section"
      aria-labelledby="booking-title"
      className="relative flex flex-col items-center bg-white pb-20 max-lg:pt-20 md:gap-y-6"
    >
      <div ref={ref} className="flex w-full flex-col gap-y-12">
        <div className="flex w-full flex-col gap-y-6">
          <div className="flex justify-center gap-x-4">
            <Typography
              className="tabular-nums text-black/20"
              variant="h2"
              family="brand"
              component="span"
            >
              03
            </Typography>
            <Typography
              className="text-black"
              variant="h2"
              id="booking-title"
              family="brand"
              component="h2"
            >
              {t('common:book')}
            </Typography>
          </div>
          <Typography
            color="shy"
            className="mx-auto max-w-xl max-md:text-balance"
            align="center"
          >
            {t('common:selectSlotDescription')}
          </Typography>
        </div>
        <SlotPicker context="Home" isLogged={isLogged} />
      </div>
      <div className="w-full text-center lg:-order-1 lg:py-8 lg:text-right">
        <Link
          underline="hover"
          className="inline-flex items-center gap-x-2 text-body1"
          to={ROUTES.bookingCompanies_form}
          testId="bookingCompaniesBookSlot"
        >
          <span>{t('common:bookForEmployees')}</span>
          <ArrowRight width={14} height={14} />
        </Link>
      </div>
    </Container>
  )
}

export default BookASlot
